/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Loader from 'Component/Loader';
import {
    FieldFile as SourceFieldFile
} from 'SourceComponent/FieldFile/FieldFile.component';

/** @namespace Satisfly/Component/FieldFile/Component */
export class FieldFile extends SourceFieldFile {
    renderFileLabel() {
        const {
            attr: { id = '', multiple = false } = {},
            fileName = '',
            isLoading = false,
            resetFieldValue,
            device: { isMobile } = {}
        } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        if (fileName) {
            return (
                <label htmlFor={ id }>
                    <p>{ fileName }</p>
                    <button onClick={ resetFieldValue }>{ __('Remove file') }</button>
                </label>
            );
        }

        const dropLabel = multiple ? __('Drop files here or') : __('Drop file here or');
        const selectLabel = multiple ? __('Select files') : __('Select file');

        return (
            <label htmlFor={ id }>
                { !isMobile && <p>{ dropLabel }</p> }
                <span block="Field" elem="SelectFileBtn">{ selectLabel }</span>
                { isMobile && __('files.') }
            </label>
        );
    }
}

export default FieldFile;
