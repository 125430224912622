/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

/** @namespace Satisfly/Component/Meta/Component */
export class Meta extends SourceMeta {
    static propTypes = {
        ...super.propTypes,
        ogmetadata: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                property: PropTypes.string,
                content: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ])
            })
        ).isRequired
    };

    renderMeta() {
        const { metadata, ogmetadata } = this.props;

        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { metadata.map((tag) => {
                    const {
                        name = null,
                        property = null,
                        content = null
                    } = tag;

                    return (
                        <meta
                          key={ name || property }
                          name={ name }
                          content={ content }
                        />
                    );
                }) }
                { ogmetadata.map((tag) => <meta key={ tag.name || tag.property } { ...tag } />) }
            </>
        );
    }
}

export default Meta;
