/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_BLOG_LIST, UPDATE_BLOG_POST } from './Blog.action';

/** @namespace Satisfly/Store/Blog/Reducer/getInitialState */
export const getInitialState = () => ({
    postList: [],
    totalItems: 0,
    totalPages: 0,
    post: {}
});

/** @namespace Satisfly/Store/Blog/Reducer/BlogReducer */
export const BlogReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_BLOG_LIST:
        const { postList } = action;

        return {
            ...state,
            postList: postList?.mpBlogPosts?.items || [],
            totalItems: postList?.mpBlogPosts?.total_count,
            totalPages: postList?.mpBlogPosts?.pageInfo.endPage
        };

    case UPDATE_BLOG_POST:
        const { post } = action;

        return {
            ...state,
            post: post?.mpBlogPosts?.items[0] || {}
        };

    default:
        return state;
    }
};

export default BlogReducer;
