/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import { CUSTOMER_ACCOUNT } from 'Component/Header/Header.config';
import { PREVIOUS_ROUTE } from 'Component/NavigationAbstract/NavigationAbstract.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    ACCOUNT_LOGIN_URL,
    ACCOUNT_URL
} from 'Route/MyAccount/MyAccount.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountOverlayContainer as SourceMyAccountOverlayContainer
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.container';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import {
    STATE_LOGGED_IN,
    STATE_SIGN_IN
} from './MyAccountOverlay.config';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Satisfly/Component/MyAccountOverlay/Container */
export class MyAccountOverlayContainer extends SourceMyAccountOverlayContainer {
    componentDidUpdate(prevProps, prevState) {
        const { isSignedIn: prevIsSignedIn } = prevProps;
        const { state: oldMyAccountState } = prevState;
        const { state: newMyAccountState } = this.state;
        const { isOverlayVisible } = this.props;
        const { location: { pathname } } = history;

        const {
            isSignedIn,
            hideActiveOverlay,
            isCheckout,
            goToPreviousHeaderState,
            redirectToDashboard
        } = this.props;

        if (oldMyAccountState === newMyAccountState) {
            return;
        }

        if (isSignedIn !== prevIsSignedIn) {
            hideActiveOverlay();

            if (isCheckout) {
                goToPreviousHeaderState();
            }
        }

        if (
            newMyAccountState !== STATE_LOGGED_IN
            && pathname.includes(ACCOUNT_URL)
            && !isOverlayVisible
        ) {
            history.push({ pathname: appendWithStoreCode(ACCOUNT_LOGIN_URL) });
        }

        if (newMyAccountState === STATE_LOGGED_IN) {
            if (pathname.includes(ACCOUNT_URL)) {
                const previousRoute = BrowserDatabase.getItem(PREVIOUS_ROUTE) || '/';
                history.replace(appendWithStoreCode(previousRoute));
            } else if (!pathname.includes(CHECKOUT_URL) && redirectToDashboard) {
                history.push({ pathname: appendWithStoreCode(ACCOUNT_URL) });
            }
        }
    }

    handleSignIn(e) {
        const { setHeaderState, isOverlayVisible } = this.props;

        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ state: STATE_SIGN_IN });

        setHeaderState({
            name: CUSTOMER_ACCOUNT,
            title: __('Sign in')
        });

        if (!isOverlayVisible) {
            history.push({ pathname: appendWithStoreCode(ACCOUNT_LOGIN_URL) });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOverlayContainer);
