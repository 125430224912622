/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE, { SPACE_KEY_CODE } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Html from 'Component/Html';
import ShowMoreLessContent from 'Component/ShowMoreLessContent';
import { MyAccountCreateAccount as SourceMyAccountCreateAccount } from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';
import { validatePassword } from 'Util/Validator';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';
import {
    getPhoneRule,
    getPostCodeRule,
    getVatRule
} from 'Util/Validator/Validator';

import {
    MANUAL_MODE,
    SHOW_MORE_LESS_CONTENT_CONSENT_LENGTH
} from './MyAccountCreateAccount.config';

import './MyAccountCreateAccount.override.style.scss';

/** @namespace Satisfly/Component/MyAccountCreateAccount/Component */
export class MyAccountCreateAccount extends SourceMyAccountCreateAccount {
    static propTypes = {
        ...super.propTypes,
        isPolishStore: PropTypes.bool.isRequired
    };

    state = {
        currentPasswordValue: ''
    };

    setCurrentPasswordValue(value) {
        this.setState({
            currentPasswordValue: value
        });
    }

    renderCreateAccountSignUpInfoFields() {
        const {
            isPolishStore,
            countries,
            defaultCountry
        } = this.props;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend" mods={ { isNonPolishStore: !isPolishStore } }>
                <legend block="MyAccountOverlay" elem="SignUpLegend">
                    { __('Company details and address') }
                </legend>
                { this.renderVatNumberField() }
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Company Name') }
                  attr={ {
                      id: 'companyname',
                      name: 'companyname',
                      placeholder: __('Enter company name')
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true
                  } }
                  addRequiredTag
                />
                { isPolishStore ? (
                    <Field
                      type={ FIELD_TYPE.mask }
                      label={ __('PKD') }
                      attr={ {
                          id: 'pkd',
                          name: 'pkd',
                          placeholder: __('Your pkd name')
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true
                      } }
                      addRequiredTag
                      mask="99.99"
                      formatChars={ { 9: '[0-9]' } }
                    />
                ) : (
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      label={ (
                          <p
                            block="MyAccountOverlay"
                            elem="SwitcherLabel"
                          >
                            { __('I run a beauty salon') }
                          </p>
                          ) }
                      attr={ {
                          id: 'isBeatutyStudio',
                          name: 'isBeatutyStudio',
                          defaultChecked: false
                      } }
                      validateOn={ ['onChange'] }
                    />
                ) }
                { !isPolishStore && (
                    <Field
                      type={ FIELD_TYPE.select }
                      attr={ {
                          id: 'country_id',
                          name: 'country_id',
                          defaultValue: defaultCountry
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true
                      } }
                      options={ countries.map(({ id, label }) => ({ id, label, value: id })) }
                      addRequiredTag
                    />
                ) }
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Address') }
                  attr={ {
                      id: 'address',
                      name: 'address',
                      placeholder: __('Enter address')
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true
                  } }
                  addRequiredTag
                />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('City') }
                  attr={ {
                      id: 'city',
                      name: 'city',
                      placeholder: __('Enter city')
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true
                  } }
                  addRequiredTag
                />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Zip / Postal code') }
                  attr={ {
                      id: 'postcode',
                      name: 'postcode',
                      placeholder: __('Enter zip / postal code')
                  } }
                  validateOn={ ['onChange', 'onBlur'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: getPostCodeRule()
                  } }
                  addRequiredTag
                />
            </fieldset>
        );
    }

    renderVatNumberField() {
        return (
            <Field
              type={ FIELD_TYPE.text }
              label={ __('Tax/VAT Number') }
              attr={ {
                  id: 'taxvat',
                  name: 'taxvat',
                  placeholder: __('Enter Tax/VAT Number')
              } }
              validateOn={ ['onChange'] }
              validationRule={ {
                  isRequired: true,
                  inputType: getVatRule()
              } }
              addRequiredTag
              events={ {
                  onKeyDown: (e) => {
                      if (e.keyCode === SPACE_KEY_CODE) {
                          e.preventDefault();
                      }
                  }
              } }
            />
        );
    }

    renderCreateAccountPersonalInfoFields() {
        const { range, minimunPasswordCharacter } = this.props;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <legend block="MyAccountOverlay" elem="PersonalInfoLegend">
                    { __('Contact person details') }
                </legend>
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('First Name') }
                  attr={ {
                      id: 'firstname',
                      name: 'firstname',
                      placeholder: __('Enter first name'),
                      autocomplete: 'given-name'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true
                  } }
                  addRequiredTag
                />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Last Name') }
                  attr={ {
                      id: 'lastname',
                      name: 'lastname',
                      placeholder: __('Enter last name'),
                      autocomplete: 'family-name'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true
                  } }
                  addRequiredTag
                />
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Email') }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      placeholder: __('Enter email name'),
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  addRequiredTag
                />
                <Field
                  type={ FIELD_TYPE.tel }
                  label={ __('Phone number') }
                  attr={ {
                      id: 'telephone',
                      name: 'telephone',
                      placeholder: __('Enter phone number')
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: getPhoneRule()
                  } }
                  addRequiredTag
                />
                <Field
                  type={ FIELD_TYPE.password }
                  label={ __('Password') }
                  attr={ {
                      id: 'password',
                      name: 'password',
                      placeholder: __('Enter your password'),
                      autocomplete: 'new-password'
                  } }
                  red={ this.passwordRef }
                  events={ {
                      onChange: (e) => {
                          this.setCurrentPasswordValue(e?.target?.value);
                      }
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.password,
                      match: (value) => {
                          const email = document.getElementById('email');

                          if (value && email.value === value) {
                              return __(
                                  "Passwords can't be the same as email!"
                              );
                          }

                          return validatePassword(
                              value,
                              range,
                              minimunPasswordCharacter
                          );
                      }
                  } }
                  addRequiredTag
                />
                <Field
                  type={ FIELD_TYPE.password }
                  label={ __('Retype your password') }
                  attr={ {
                      id: 'confirm_password',
                      name: 'confirm_password',
                      placeholder: __('Retype your password'),
                      autocomplete: 'new-password'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.password,
                      match: (value) => {
                          const { currentPasswordValue } = this.state;

                          return value && currentPasswordValue === value;
                      },
                      customErrorMessages: {
                          onMatchFail: __('Passwords do not match!')
                      }
                  } }
                  addRequiredTag
                />
            </fieldset>
        );
    }

    renderCreateAccountPersonalAttachmentsFields() {
        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <legend block="MyAccountOverlay" elem="AttachmentLegend">
                    { __('Attachments') }
                    <span className="Field-Label Field-Label_isRequired">
                        *
                    </span>
                </legend>
                <p>
                    { __(
                        'Please attach files confirming qualifications or running a beauty salon.'
                    ) }
                </p>
                <Field
                  type={ FIELD_TYPE.file }
                  attr={ {
                      id: 'attachments',
                      name: 'attachments',
                      multiple: true
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true
                  } }
                  addRequiredTag
                />
            </fieldset>
        );
    }

    renderCreateAccountConsentsFields() {
        const { termsAreEnabled, termsAndConditions } = this.props;

        if (!termsAreEnabled) {
            return null;
        }

        return (
            <fieldset
              block="MyAccountOverlay"
              mods={ { isConsents: true } }
              elem="Legend"
            >
                { termsAndConditions?.map(
                    ({
                        name, checkbox_text, mode, content, agreement_id
                    }) => {
                        const isRequired = mode === MANUAL_MODE;

                        return (
                            <Field
                              type={ FIELD_TYPE.checkbox }
                              label={ (
                                    <>
                                        <p
                                          block="MyAccountOverlay"
                                          elem="ConsentLabel"
                                        >
                                            { checkbox_text }
                                            { isRequired && (
                                                <span className="Field-Label Field-Label_isRequired">
                                                    { ' ' }
                                                    *
                                                </span>
                                            ) }
                                        </p>
                                        { content && (
                                            <ShowMoreLessContent
                                              withDots
                                              isPB
                                              contentLength={ SHOW_MORE_LESS_CONTENT_CONSENT_LENGTH }
                                            >
                                                <Html content={ content } />
                                            </ShowMoreLessContent>
                                        ) }
                                    </>
                                  ) }
                              attr={ {
                                  id: agreement_id,
                                  name
                              } }
                              validateOn={ ['onChange'] }
                              validationRule={ {
                                  isRequired
                              } }
                              addRequiredTag={ !!isRequired }
                            />
                        );
                    }
                ) }
                { this.renderRequiredInfo() }
            </fieldset>
        );
    }

    renderRequiredInfo() {
        return (
            <p block="MyAccountOverlay" elem="RequiredInfo">
                <span>*</span>
                { __('Required fields.') }
            </p>
        );
    }

    render() {
        const { onError, onSuccess } = this.props;

        return (
            <Form
              key="create-account"
              onSubmit={ onSuccess }
              onError={ onError }
            >
                { this.renderCreateAccountSignUpInfoFields() }
                { this.renderCreateAccountPersonalInfoFields() }
                { this.renderCreateAccountPersonalAttachmentsFields() }
                { this.renderCreateAccountConsentsFields() }
                { this.renderSubmitButton() }
            </Form>
        );
    }
}

export default MyAccountCreateAccount;
