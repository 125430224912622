/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { STATE_CONFIRM_EMAIL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import MyAccountQuery from 'Query/MyAccount.query';
import { ACCOUNT_LOGIN_URL } from 'Route/MyAccount/MyAccount.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccountContainer
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';
import { CountriesType } from 'Type/Config.type';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { fetchMutation } from 'Util/Request';
import { isPolishStore } from 'Util/Store/Config';
import transformCountriesToOptions from 'Util/Store/Transform';
import { appendWithStoreCode } from 'Util/Url';

import { CONFIRMATION_REQUIRED, DEFAULT_BEAUTY_STUDIO_PKD } from './MyAccountCreateAccount.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Satisfly/Component/MyAccountCreateAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    defaultCountry: state.ConfigReducer.default_country,
    termsAndConditions: state.ConfigReducer.checkoutAgreements,
    termsAreEnabled: state.ConfigReducer.terms_are_enabled,
    countries: transformCountriesToOptions(state.ConfigReducer.countries)
});

/** @namespace Satisfly/Component/MyAccountCreateAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateCustomerDetails: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    )
});

/** @namespace Satisfly/Component/MyAccountCreateAccount/Container */
export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccountContainer {
    static propTypes = {
        ...super.PropTypes,
        countries: CountriesType.isRequired,
        defaultCountry: PropTypes.string.isRequired,
        updateCustomerDetails: PropTypes.func.isRequired
    };

    containerProps() {
        const {
            termsAndConditions,
            termsAreEnabled,
            countries,
            defaultCountry
        } = this.props;

        return {
            ...super.containerProps(),
            termsAndConditions,
            termsAreEnabled,
            countries,
            defaultCountry,
            isPolishStore: isPolishStore()
        };
    }

    _getPkd(pkd = '', isBeatutyStudio = false) {
        if (isPolishStore()) {
            return pkd;
        }

        return isBeatutyStudio ? DEFAULT_BEAUTY_STUDIO_PKD : '';
    }

    getTaxVatValue(taxValue) {
        if (!isPolishStore()) {
            return taxValue?.toUpperCase();
        }

        return taxValue;
    }

    async onSuccess(form, fields) {
        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            isLandingPage,
            showNotification,
            isMobile,
            defaultCountry,
            updateCustomerDetails
        } = this.props;

        const {
            country_id,
            password,
            email,
            firstname,
            lastname,
            pkd,
            address: streetAddress,
            is_subscribed,
            taxvat,
            companyname,
            postcode,
            telephone,
            city,
            attachments,
            isBeatutyStudio
        } = transformToNameValuePair(fields);

        const files = [];
        const isMultipleFiles = typeof attachments !== 'string';

        if (isMultipleFiles) {
            attachments.forEach((attachment) => {
                files.push({
                    file_data: attachment.data,
                    file_name: attachment.name
                });
            });
        } else {
            files.push(JSON.parse(attachments));
        }

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed,
                taxvat: this.getTaxVatValue(taxvat),
                password,
                pkd: this._getPkd(pkd, isBeatutyStudio),
                files: Array.from(files)
            }
        };

        if (isLoading) {
            return;
        }

        try {
            const code = await createAccount(customerData).catch(
                /** @namespace Satisfly/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/code/createAccount/catch/showNotification */
                (e) => showNotification('error', __('An error has occurred!'), e)
            );

            const address = {
                firstname,
                lastname,
                vat_id: taxvat,
                company: companyname,
                postcode,
                telephone,
                city,
                country_id: country_id || defaultCountry,
                street: [streetAddress],
                region: {
                    region: null,
                    region_code: null,
                    region_id: 0
                },
                default_billing: true,
                default_shipping: true
            };

            const query = MyAccountQuery.getCreateAddressMutation(address);

            await fetchMutation(query);

            await updateCustomerDetails();

            // if user needs confirmation
            if (code === CONFIRMATION_REQUIRED) {
                setSignInState(STATE_CONFIRM_EMAIL);

                if (isLandingPage || isMobile) {
                    showNotification(
                        'success',
                        // eslint-disable-next-line max-len
                        __('The email confirmation link has been sent to your email. Please confirm your account to proceed.')
                    );
                    history.push({ pathname: appendWithStoreCode(ACCOUNT_LOGIN_URL) });
                }
            } else if (code !== false) {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
