/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ACCOUNT_ORDER_HISTORY } from 'Route/MyAccount/MyAccount.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountOrderContainer as SourceContainer
} from 'SourceComponent/MyAccountOrder/MyAccountOrder.container';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

export { mapDispatchToProps };

/** @namespace Satisfly/Component/MyAccountOrder/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    dpdCarrierTrackingUrl: state.ConfigReducer.carriers_Dpdshipping_tracking_url
});

/** @namespace Satisfly/Component/MyAccountOrder/Container */
export class MyAccountOrderContainer extends SourceContainer {
    static propTypes = {
        ...super.propTypes,
        dpdCarrierTrackingUrl: PropTypes.string.isRequired
    };

    containerProps() {
        const {
            dpdCarrierTrackingUrl
        } = this.props;

        return {
            ...super.containerProps(),
            dpdCarrierTrackingUrl
        };
    }

    async requestOrderDetails() {
        const {
            match: {
                params: {
                    orderId
                }
            },
            getOrderById,
            changeTabName
        } = this.props;

        if (!isSignedIn()) {
            return;
        }

        const order = await getOrderById(orderId);

        if (!order) {
            history.replace(appendWithStoreCode(`${ ACCOUNT_ORDER_HISTORY }`));

            return;
        }

        const { increment_id, id: uid } = order;

        // decode uid of order before setting into state
        order.id = atob(uid);

        changeTabName((__('Order %s', increment_id)));

        this.handleChangeHeaderState();
        this.setState({ order, isLoading: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderContainer);
