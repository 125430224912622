/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceComponent/Router/Router.config';

export const MY_ACCOUNT_DOCUMENTS = 'MY_ACCOUNT_DOCUMENTS';
export const MY_ACCOUNT_MENU_PAGE = 'MY_ACCOUNT_MENU_PAGE';
export const LOGIN_AS_CUSTOMER = 'LOGIN_AS_CUSTOMER';
