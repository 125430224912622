/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { getQueryParam } from 'SourceUtil/Url/Url';
import { appendWithStoreCode } from 'Util/Url';

import { PROMO_BROCHURE_URL_KEY } from './Url.config';

export * from 'SourceUtil/Url/Url';

/** @namespace Satisfly/Util/Url/parseCustomFiltersToObject */
export const parseCustomFiltersToObject = (location) => {
    if (!location || !getQueryParam('customFilters', location)) {
        return {};
    }

    return Object.fromEntries(
        getQueryParam('customFilters', location)
            .split(';')
            .map((filter) => filter.split(':'))
            .map(([name, value]) => {
                if (value.includes('_')) {
                    const vS = value.split('_');

                    return [
                        name,
                        {
                            min: parseInt(vS[0], 10) ?? 0,
                            max: parseInt(vS[1], 10) ?? 0
                        }
                    ];
                }

                return [name, value];
            })
    );
};

/** @namespace Satisfly/Util/Url/parseCustomFiltersToUrl */
export const parseCustomFiltersToUrl = (filters) => {
    if (!filters) {
        return '';
    }

    return Object.entries(filters)
        .map(([name, value]) => {
            if (value.min || value.max) {
                return [name, `${value.min}_${value.max}`];
            }

            return [name, value];
        })
        .map((filter) => filter.join(':'))
        .join(';');
};

/** @namespace Satisfly/Util/Url/isPromoBrochurePage */
export const isPromoBrochurePage = (pathname) => {
    const isPromoBrochurePage = pathname === appendWithStoreCode(`/${PROMO_BROCHURE_URL_KEY}`);

    return isPromoBrochurePage;
};
