/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

/**
 * Field
 * @class FieldContainer
 * @namespace Satisfly/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        mask: PropTypes.string,
        formatChars: PropTypes.object
    };

    static defaultProps = {
        ...SourceFieldContainer.defaultProps,
        mask: '',
        formatChars: {
            9: '[0-9]',
            a: '[A-Za-z]',
            '*': '[A-Za-z0-9]'
        }
    };

    containerProps() {
        const {
            mask,
            formatChars
        } = this.props;

        return {
            ...super.containerProps(),
            mask,
            formatChars
        };
    }
}

export default FieldContainer;
