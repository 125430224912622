/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { FIELD_TYPE as SOURCE_FIELD_TYPE } from 'SourceComponent/Field/Field.config';

export const FIELD_TYPE = {
    ...SOURCE_FIELD_TYPE,
    mask: 'mask'
};

export const FIELD_RADIO_NONE = 'none';
export const SPACE_KEY_CODE = 32;

export default FIELD_TYPE;
