/* eslint-disable react/forbid-elements */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Link as RouterLink } from 'react-router-dom';
import { stringify } from 'rebem-classname';

import {
    Link as SourceLink
} from 'SourceComponent/Link/Link.component';

import { ANCHOR_NEW_TAB } from './Link.config';

import './Link.style.scss';

/** @namespace Satisfly/Component/Link/Component */
export class Link extends SourceLink {
    renderRelativePathLink() {
        const {
            isOpenInNewTab,
            children,
            to,
            target = '',
            ...props
        } = this.props;

        if (isOpenInNewTab || target === ANCHOR_NEW_TAB) {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <a
                  { ...props }
                  onClick={ this.scrollToElement }
                  href={ to }
                  rel="noopener noreferrer"
                  target="_blank"
                  block="LinkBlank"
                >
                    { children }
                    <span block="LinkBlank" elem="NewTabTooltip">
                        { __('Opens in new tab') }
                    </span>
                </a>
            );
        }

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <a
              { ...props }
              onClick={ this.scrollToElement }
              href={ to }
            >
                { children }
            </a>
        );
    }

    renderAbsolutePathLink(classNameConverted) {
        const {
            isOpenInNewTab,
            children,
            to,
            bemProps,
            target = '',
            onClick,
            ...props
        } = this.props;

        const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);

        if (isOpenInNewTab || target === ANCHOR_NEW_TAB) {
            return (
                <a
                  { ...props }
                  href={ to }
                    // eslint-disable-next-line react/forbid-dom-props
                  className={ classNameConverted }
                  rel="noopener noreferrer"
                  target="_blank"
                  block="LinkBlank"
                >
                    { children }
                    <span block="LinkBlank" elem="NewTabTooltip">
                        { __('Opens in new tab') }
                    </span>
                </a>
            );
        }

        return (
            <a
              { ...props }
              href={ to }
                // eslint-disable-next-line react/forbid-dom-props
              className={ classNameConverted }
              onClick={ isAbsoluteUrl(to) ? () => {} : onClick }
            >
                { children }
            </a>
        );
    }

    render() {
        const {
            className,
            bemProps,
            children,
            to,
            isOpenInNewTab,
            ...props
        } = this.props;

        const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);

        if (!to) {
            return (
                <div { ...props } { ...bemProps }>
                    { children }
                </div>
            );
        }

        if (/^#/.test(to)) {
            return this.renderRelativePathLink();
        }

        const classNameConverted = `${ className } ${ stringify(bemProps)}`;

        if (/^https?:\/\//.test(to) || isOpenInNewTab || isAbsoluteUrl(to)) {
            return this.renderAbsolutePathLink(classNameConverted);
        }

        return (
            <RouterLink
              { ...props }
              to={ to }
              // eslint-disable-next-line react/forbid-component-props
              className={ classNameConverted }
            >
                { children }
            </RouterLink>
        );
    }
}

export default Link;
