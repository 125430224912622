/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const DISPLAY_CART_TAX_IN_SHIPPING = {
    INCL_TAX: 'DISPLAY_CART_TAX_IN_SHIPPING_INCL_TAX',
    EXCL_TAX: 'DISPLAY_CART_TAX_IN_SHIPPING_EXL_TAX',
    BOTH: 'DISPLAY_CART_TAX_IN_SHIPPING_BOTH'
};

export * from 'SourceUtil/Cart/Cart';

export const GUEST_QUOTE_ID = 'guest_quote_id';

/** @namespace Satisfly/Util/Cart/getCartShippingPrice */
export const getCartShippingPrice = (state) => {
    const {
        ConfigReducer: {
            cartDisplayConfig: {
                display_tax_in_shipping_amount = ''
            } = {}
        } = {},
        CartReducer: {
            cartTotals: {
                shipping_addresses: {
                    selected_shipping_method = {},
                    available_shipping_methods = []
                } = []
            } = {}
        } = {}
    } = state;

    const {
        amount: {
            value: shipping_amount = 0
        } = {},
        amount_incl_tax: shipping_incl_tax = 0
    } = selected_shipping_method || {};

    const isMethodSelected = !!Object.keys(selected_shipping_method || {}).length;

    const {
        amount: {
            value: available_shipping_amount = 0
        } = {},
        price_incl_tax: {
            value: available_shipping_incl_tax = 0
        } = {}
    } = available_shipping_methods[0] || {};

    if (display_tax_in_shipping_amount === DISPLAY_CART_TAX_IN_SHIPPING.EXCL_TAX) {
        return isMethodSelected ? shipping_amount : (available_shipping_amount || null);
    }

    return isMethodSelected ? shipping_incl_tax : (available_shipping_incl_tax || null);
};
