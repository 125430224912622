/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-useless-fragment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import { lazy } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import ShowMore from 'Component/ShowMore';
import { Html as SourceHtml } from 'SourceComponent/Html/Html.component';
import isMobile from 'Util/Mobile';

import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from './Html.config';

export const WidgetFactory = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget" */
    'Component/WidgetFactory'
));

/**
 * Html content parser
 * Component converts HTML strings to React components
 * @class Html
 * @namespace Satisfly/Component/Html/Component */
export class Html extends SourceHtml {
    static propTypes = {
        content: PropTypes.string.isRequired,
        withoutHandlingAnchors: PropTypes.bool,
        preventLazyImages: PropTypes.bool,
        isPromoBrochure: PropTypes.bool
    };

    static defaultProps = {
        withoutHandlingAnchors: false,
        preventLazyImages: false,
        isPromoBrochure: false
    };

    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { attribs: ['data-show-more'] },
            replace: this.prepareShowMore
        }
    ];

    replaceLinks({ attribs, children }) {
        const { href, ...attrs } = attribs;

        if (href) {
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

            if (!isSpecialLink(href)) {
                return (
                    <Link
                      onClick={ this.scrollToTopFunction }
                      { ...attributesToProps({ ...attrs, to: href }) }
                    >
                        { domToReact(children, this.parserOptions) }
                    </Link>
                );
            }
        }
    }

    replaceWidget({ attribs }) {
        const {
            isPromoBrochure
        } = this.props;

        return (
            <WidgetFactory { ...this.attributesToProps(attribs) } isPromoBrochure={ isPromoBrochure } />
        );
    }

    prepareShowMore({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);

        return (
            <ShowMore { ...attributes }>{ domToReact(children, this.parserOptions) }</ShowMore>
        );
    }

    componentDidMount() {
        const { withoutHandlingAnchors } = this.props;

        if (!withoutHandlingAnchors) {
            this.handleAnchor();
        }
    }

    componentWillUnmount() {
        const { withoutHandlingAnchors } = this.props;

        if (!withoutHandlingAnchors) {
            this.handleAnchor(true);
        }
    }

    handleAnchor(unmount) {
        const isAnchor = (value) => value?.match('#');
        const domElements = document.querySelectorAll('.CmsPage a');
        const selectors = Array.from(domElements).filter((o) => isAnchor(o.getAttribute('href')));

        selectors.forEach((anchor) => {
            const link = anchor.getAttribute('href');
            const id = link.slice(link.indexOf('#') + 1);
            const idElement = document.getElementById(id);

            if (idElement) {
                if (unmount) {
                    anchor.removeEventListener('click', (event) => this.handleAnchorClickHandle(event, idElement));
                }

                anchor.addEventListener('click', (event) => this.handleAnchorClickHandle(event, idElement));
            }
        });
    }

    handleAnchorClickHandle(event, idElement) {
        event.preventDefault();
        event.stopPropagation();
        const headerOffset = isMobile.any() ? MOBILE_HEADER_HEIGHT : HEADER_HEIGHT;
        const elementPosition = idElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    replaceImages({ attribs }) {
        const {
            preventLazyImages
        } = this.props;

        const attributes = attributesToProps(attribs);

        if (attribs.src) {
            return <Image { ...attributes } isPlain preventLazy={ preventLazyImages } />;
        }
    }
}

export default Html;
