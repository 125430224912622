/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceRoute/Checkout/Checkout.config';

export const EXTERNAL_PAYMENT_ORDER_ID = 'EXTERNAL_PAYMENT_ORDER_ID';
export const ALL_CONSENTS = 'all-consents';
