/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './MyAccountOrderStatus.style';

/** @namespace Satisfly/Component/MyAccountOrderStatus/Component */
export class MyAccountOrderStatus extends PureComponent {
    static propTypes = {
        status: PropTypes.string.isRequired
    };

    render() {
        const { status } = this.props;

        if (!status || status === '') {
            return null;
        }

        return (
            <span block="MyAccountOrderStatus">
                { status }
            </span>
        );
    }
}

export default MyAccountOrderStatus;
