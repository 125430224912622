/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    CART_TOTALS,
    updateCartTotals,
    updateShippingPrice
} from 'SourceStore/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';

import {
    UPDATE_FREE_GIFTS, UPDATE_IS_LOADING_CART, UPDATE_IS_LOADING_GIFTS,
    UPDATE_SHIPPING_PRICE, UPDATE_TOTALS
} from './Cart.action';

export {
    CART_TOTALS,
    updateCartTotals,
    updateShippingPrice
};

/** @namespace Satisfly/Store/Cart/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoading: false,
    isLoadingGifts: false,
    cartTotals: BrowserDatabase.getItem(CART_TOTALS) || {},
    freeGifts: []
});

/** @namespace Satisfly/Store/Cart/Reducer/CartReducer */
export const CartReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_TOTALS:
        return {
            ...state,
            ...updateCartTotals(action, state)
        };
    case UPDATE_SHIPPING_PRICE:
        return {
            ...state,
            ...updateShippingPrice(action, state)
        };
    case UPDATE_FREE_GIFTS:
        const { freeGifts } = action;

        return {
            ...state,
            freeGifts
        };
    case UPDATE_IS_LOADING_CART:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    case UPDATE_IS_LOADING_GIFTS:
        const { isLoadingGifts } = action;

        return {
            ...state,
            isLoadingGifts
        };
    default:
        return state;
    }
};

export default CartReducer;
