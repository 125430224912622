/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { MyAccountOrderTotals as SourceMyAccountOrderTotals } from 'SourceComponent/MyAccountOrderTotals/MyAccountOrderTotals.component';
import { formatPrice } from 'Util/Price';

import './MyAccountOrderTotals.override.style';

/** @namespace Satisfly/Component/MyAccountOrderTotals/Component */
export class MyAccountOrderTotals extends SourceMyAccountOrderTotals {
    renderDiscount({ label, amount: { value } }, index) {
        const discountLabel = label ? __('Discount (%s)', label) : __('Discount');

        return this.renderPriceLine(discountLabel, value, null, { block: 'MyAccountOrderTotals', elem: 'Discount' }, `discount-${index}`);
    }

    renderContent() {
        const {
            total: {
                subtotal: {
                    value: subtotalPrice
                },
                shipping_handling: {
                    total_amount: {
                        value: shippingHandlingPrice
                    }
                },
                grand_total: {
                    value: grandTotalPrice
                },
                total_tax: {
                    value: totalTaxPrice
                }
            }
        } = this.props;

        const grandTotalMix = { block: 'MyAccountOrderTotals', elem: 'GrandTotal' };

        return (
            <>
                { this.renderPriceLine(__('Subtotal'), subtotalPrice) }
                { this.renderDiscounts() }
                { this.renderPriceLine(__('Shipping & Handling'), shippingHandlingPrice) }
                { this.renderPriceLine(__('Tax'), totalTaxPrice) }
                <div block="MyAccountOrderTotals" elem="FinalPrices">
                    { this.renderPriceLine(
                        __('excl. tax'),
                        grandTotalPrice - totalTaxPrice,
                        null,
                        grandTotalMix
                    ) }
                    { this.renderPriceLine(__('Incl. tax'), grandTotalPrice, null, grandTotalMix) }
                </div>
            </>
        );
    }

    render() {
        const { total } = this.props;

        if (!total) {
            return null;
        }

        return (
            <div
              block="MyAccountOrderTotals"
              elem="Wrapper"
            >
                <h4 block="MyAccountOrderTotals" elem="Title">{ __('Summary') }</h4>
                <div block="MyAccountOrderTotals" elem="Content">
                    { this.renderContent() }
                </div>
            </div>
        );
    }

    renderTax(tax) {
        const { amount: { value, currency }, title, rate } = tax;

        return (
            <div key={ `${title}-${rate}` }>
                <div><div>{ `${title} (${rate})` }</div></div>
                <div><div>{ formatPrice(value, currency) }</div></div>
            </div>
        );
    }

    renderPriceLine(title, price, currency, mix = {}, key) {
        const {
            total: { grand_total: { currency: defaultCurrency } }
        } = this.props;

        return (
            <div mix={ mix } key={ key }>
                <div><div>{ title }</div></div>
                <div><div>{ formatPrice(price, currency || defaultCurrency) }</div></div>
            </div>
        );
    }
}

export default MyAccountOrderTotals;
