/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

/** @namespace Satisfly/Util/Date/formatDateWithSlashes */
export const formatDateWithSlashes = (rawDate = '') => {
    const date = new Date(rawDate.replace(/\s/, 'T'));
    const RADIX = 10;

    const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);
    const day = addLeadingZero(date.getDate()) || '--';
    const month = addLeadingZero(date.getMonth() + 1) || '--';
    const year = date.getFullYear() || '----';

    return `${day}/${month}/${year}`;
};
