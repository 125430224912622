/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import getStore from 'Util/Store';

export const STORE_PL_CODE = 'b2b_pl';
export const STORE_DE_CODE = 'b2b_de';
export const STORE_EN_CODE = 'b2b_en';

export const MENU_DEFAULT_ID = 'main-menu';

export const STORES = {
    [STORE_PL_CODE]: {
        id: STORE_PL_CODE,
        name: 'PL',
        value: STORE_PL_CODE,
        label: 'PL',
        url: 'https://clarena.pro/b2b_pl'
    },
    [STORE_DE_CODE]: {
        id: STORE_DE_CODE,
        name: 'DE',
        value: STORE_DE_CODE,
        label: 'DE',
        url: 'https://clarena.pro/b2b_de'
    },
    [STORE_EN_CODE]: {
        id: STORE_EN_CODE,
        name: 'EN',
        value: STORE_EN_CODE,
        label: 'EN',
        url: 'https://clarena.pro/b2b_en'
    }
};

/** @namespace Satisfly/Util/Store/Config/isPolishStore */
export const isPolishStore = () => getStore().getState()?.ConfigReducer?.code === STORE_PL_CODE;

/** @namespace Satisfly/Util/Store/Config/isNonPolishStore */
export const isNonPolishStore = () => getStore().getState()?.ConfigReducer?.code !== STORE_PL_CODE;
