/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const PROMO_BROCHURE_URL_KEY = 'gazetka-promocyjna';
export const PROMO_BROCHURE_CLASS = 'PromoBrochure';
export const TO_DOWNLOAD_URL_KEY = 'to-download';
