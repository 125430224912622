/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';

/** @namespace Satisfly/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    locale: state.ConfigReducer.locale,
    'og:title': state.MetaReducer['og:title'],
    'og:description': state.MetaReducer['og:description'],
    'og:image': state.MetaReducer['og:image'],
    'og:url': state.MetaReducer['og:url'],
    'og:type': state.MetaReducer['og:type'],
    'og:site_name': state.MetaReducer['og:site_name'],
    'og:subtitle': state.MetaReducer['og:subtitle'],
    'product:retailer_part_no': state.MetaReducer['product:retailer_part_no'],
    'product:price:amount': state.MetaReducer['product:price:amount'],
    'product:sale_price:amount': state.MetaReducer['product:sale_price:amount'],
    'product:price:currency': state.MetaReducer['product:price:currency'],
    'fb:app_id': state.MetaReducer['fb:app_id']
});

/** @namespace Satisfly/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        ...super.propTypes,
        locale: PropTypes.string.isRequired,
        'og:title': PropTypes.string,
        'og:description': PropTypes.string,
        'og:image': PropTypes.string,
        'og:url': PropTypes.string,
        'og:type': PropTypes.string,
        'og:site_name': PropTypes.string,
        'og:subtitle': PropTypes.string,
        'product:retailer_part_no': PropTypes.string,
        'product:price:amount': PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        'product:sale_price:amount': PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        'product:price:currency': PropTypes.string,
        'fb:app_id': PropTypes.string
    };

    static defaultProps = {
        ...super.defaultProps,
        'og:title': '',
        'og:description': '',
        'og:image': '',
        'og:url': '',
        'og:type': 'website',
        'og:site_name': '',
        'og:subtitle': '',
        'product:retailer_part_no': '',
        'product:price:amount': 0,
        'product:sale_price:amount': 0,
        'product:price:currency': '',
        'fb:app_id': ''
    };

    containerProps() {
        return {
            ...super.containerProps(),
            ogmetadata: this._getOGMetadata()
        };
    }

    _getOGUrl() {
        return this.props['og:url'];
    }

    _getOGTitle() {
        return this.props['og:title'];
    }

    _getOGDescription() {
        return this.props['og:description'];
    }

    _getOGImage() {
        return this.props['og:image'];
    }

    _getOGType() {
        return this.props['og:type'];
    }

    _getOGSiteName() {
        return this.props['og:site_name'];
    }

    _getOGSubtitle() {
        return this.props['og:subtitle'];
    }

    _getOGRetailerPartNo() {
        return this.props['product:retailer_part_no'];
    }

    _getOGRegularPrice() {
        return this.props['product:price:amount'];
    }

    _getOGSalePrice() {
        return this.props['product:sale_price:amount'];
    }

    _getOGCurrency() {
        return this.props['product:price:currency'];
    }

    _getFbAppId() {
        return this.props['fb:app_id'];
    }

    _getOGMetadata() {
        const {
            locale = 'pl_PL'
        } = this.props;

        const meta = {
            'og:title': this._getOGTitle(),
            'og:description': this._getOGDescription(),
            'og:image': this._getOGImage(),
            'og:url': this._getOGUrl(),
            'og:type': this._getOGType(),
            'og:site_name': this._getOGSiteName(),
            'og:subtitle': this._getOGSubtitle(),
            'og:locale': locale,
            'product:retailer_part_no': this._getOGRetailerPartNo(),
            'product:price:amount': this._getOGRegularPrice(),
            'product:sale_price:amount': this._getOGSalePrice(),
            'product:price:currency': this._getOGCurrency(),
            'fb:app_id': this._getFbAppId()
        };

        return this._generateMetaFromMetadata(meta, 'property');
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
