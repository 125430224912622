/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import { MyAccountOrderInformation as SourceMyAccountOrderInformation } from 'SourceComponent/MyAccountOrderInformation/MyAccountOrderInformation.component';

import './MyAccountOrderInformation.override.style';

/** @namespace Satisfly/Component/MyAccountOrderInformation/Component */
export class MyAccountOrderInformation extends SourceMyAccountOrderInformation {
    renderPaymentMethods() {
        const { order: { payment_methods = [] } } = this.props;

        return (
            <div
              block="MyAccountOrderInformation"
              elem="Column"
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="ColumnTitle"
                >
                    <strong>
                        <span>{ __('Payment method') }</span>
                    </strong>
                </div>
                { payment_methods.map(this.renderPaymentMethod.bind(this)) }
            </div>
        );
    }

    renderComments() {
        const { order: { client_comment = '' } } = this.props;

        return (
            <div
              block="MyAccountOrderInformation"
              elem="Comments"
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="ColumnTitle"
                >
                    <strong>
                        <span>{ __('Comments to order') }</span>
                    </strong>
                </div>
                <div
                  block="MyAccountOrderInformation"
                  elem="CommentsText"
                  mods={ { noComments: !client_comment } }
                >
                    { client_comment || __('No comments added') }
                </div>
            </div>
        );
    }

    renderShippingAddress() {
        const { order: { shipping_address } = {} } = this.props;

        if (!shipping_address) {
            return null;
        }

        return (
            <div
              block="MyAccountOrderInformation"
              elem="Column"
              mods={ { type: 'shippingAddress' } }
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="ColumnTitle"
                >
                    <strong>
                        <span>{ __('Shipping address') }</span>
                    </strong>
                </div>
                <MyAccountAddressTable
                  address={ shipping_address }
                  mix={ { block: 'MyAccountOrderInformation', elem: 'Address' } }
                />
            </div>
        );
    }

    renderBillingAddress() {
        const { order: { billing_address } = {} } = this.props;

        if (!billing_address) {
            return null;
        }

        return (
            <div
              block="MyAccountOrderInformation"
              elem="Column"
              mods={ { type: 'billingAddress' } }
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="ColumnTitle"
                >
                    <strong>
                        <span>{ __('Billing address') }</span>
                    </strong>
                </div>
                <MyAccountAddressTable
                  address={ billing_address }
                  mix={ { block: 'MyAccountOrderInformation', elem: 'Address' } }
                  isOrder
                />
            </div>
        );
    }

    renderContent() {
        return (
            <div
              block="MyAccountOrderInformation"
              elem="Information"
            >
                <div block="MyAccountOrderInformation" elem="Info">
                    <div>
                        { this.renderBillingAddress() }
                        { this.renderShippingAddress() }
                    </div>
                    <div>
                        { this.renderPaymentMethods() }
                        { this.renderShippingMethod() }
                    </div>
                </div>
                { this.renderComments() }
            </div>
        );
    }
}

export default MyAccountOrderInformation;
