/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ACCOUNT_REGISTRATION_URL } from 'Route/MyAccount/MyAccount.config';

export * from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.config';

export const LOGIN_PRIVATE_REDIRECT_URL = 'https://www.e-clarena.eu/register?utm_source=clarena.pro&utm_medium=referral&utm_campaign=mini-cart-register-clarena-pro';
export const LOGIN_PROFESSIONAL_REDIRECT_URL = ACCOUNT_REGISTRATION_URL;
