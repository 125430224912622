/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import { DirectionType } from 'Type/Direction.type';

import { RIGHT } from './ArrowIcon.config';

import './ArrowIcon.style';

/** @namespace Satisfly/Component/ArrowIcon/Component */
export class ArrowIcon extends PureComponent {
    static propTypes = {
        direction: DirectionType
    };

    static defaultProps = {
        direction: RIGHT
    };

    render() {
        const { direction } = this.props;

        return (
            <svg
              block="ArrowIcon"
              mods={ { direction } }
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M5 12H19" stroke="#25131E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 5L19 12L12 19" stroke="#25131E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

export default ArrowIcon;
