/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import Link from 'Component/Link';
import MyAccountOrderStatus from 'Component/MyAccountOrderStatus';
import { ACCOUNT_ORDER_PRINT_URL } from 'Route/MyAccount/MyAccount.config';
import { MyAccountOrder as SourceMyAccountOrder } from 'SourceComponent/MyAccountOrder/MyAccountOrder.component';
import { ReactComponent as RefreshIcon } from 'Style/icons/refresh.svg';
import { formatDateWithSlashes } from 'Util/Date';
import { formatPrice } from 'Util/Price';
import { appendWithStoreCode } from 'Util/Url';

import {
    ORDER_ACTION_LABELS
} from './MyAccountOrder.config';

import './MyAccountOrder.override.style';

/** @namespace Satisfly/Component/MyAccountOrder/Component */
export class MyAccountOrder extends SourceMyAccountOrder {
    static propTypes = {
        ...super.propTypes,
        dpdCarrierTrackingUrl: PropTypes.string.isRequired
    };

    renderSubscriptionButton() {
        const { order: { rss_link }, rss_order_subscribe_allow } = this.props;

        if (!rss_order_subscribe_allow) {
            return null;
        }

        return (
            <Link
              block="MyAccountOrder"
              elem="SubscribeToStatus"
              to={ rss_link }
              isOpenInNewTab
              aria-label={ __('Subscribe to Order Status') }
            >
                { __('Subscribe to Order Status') }
            </Link>
        );
    }

    renderPrintOrder() {
        const { order: { id } } = this.props;

        return (
            <Link
              block="MyAccountOrder"
              elem="SubscribeToStatus"
              to={ appendWithStoreCode(`${ACCOUNT_ORDER_PRINT_URL}/${id}`) }
              isOpenInNewTab
              aria-label={ __('Print Order') }
            >
                { __('Print Order') }
            </Link>
        );
    }

    renderPrintAllAction() {
        const { activeTab, order: { id } } = this.props;

        const { printAllUrl, printAll } = ORDER_ACTION_LABELS[activeTab] || {};

        if (!printAllUrl) {
            return null;
        }

        return (
            <Link
              block="MyAccountOrder"
              elem="PrintOrder"
              to={ appendWithStoreCode(`${printAllUrl}/${id}`) }
              isOpenInNewTab
              aria-label={ printAll }
            >
                { printAll }
            </Link>
        );
    }

    renderContent() {
        const { order: { items } } = this.props;

        if (!items) {
            return null;
        }

        return (
            <>
                { this.renderActions() }
                { this.renderActiveTab() }
                { this.renderOrderInformation() }
            </>
        );
    }

    renderActions() {
        return (
            <div block="MyAccountOrder" elem="Actions">
                { this.renderAdditionalInfoTable() }
            </div>
        );
    }

    renderReorderButton() {
        const { is_allowed_reorder, handleReorder, order: { can_reorder } } = this.props;

        if (!is_allowed_reorder || !can_reorder) {
            return null;
        }

        return (
            <button
              block="Button"
              mods={ { likeLink: true } }
              mix={ { block: 'MyAccountOrder', elem: 'Reorder' } }
              onClick={ handleReorder }
            >
                <RefreshIcon />
                <span>
                    { __('Reorder') }
                </span>
            </button>
        );
    }

    renderTrackingNumbers(tracking) {
        const {
            dpdCarrierTrackingUrl
        } = this.props;

        if (!tracking || !tracking.length) {
            return (
                <span>-</span>
            );
        }

        return tracking.map((parcel, i) => (
                <a
                  href={ `${dpdCarrierTrackingUrl}${parcel?.number}` }
                  target="_blank"
                  rel="noreferrer"
                >
                    { `${parcel?.number}${i === tracking.length - 1 ? '' : ', '}` }
                </a>
        ));
    }

    renderAdditionalInfoTable() {
        const {
            isMobile,
            order: {
                order_date,
                increment_id,
                status,
                total: {
                    grand_total: {
                        value: grandTotalValue,
                        currency: grandTotalCurrency
                    } = {}
                } = {},
                shipments = []
            }
        } = this.props;

        const orderTotal = formatPrice(grandTotalValue, grandTotalCurrency);

        const tracking = shipments[0]?.tracking;

        if (isMobile) {
            return (
                <div
                  block="MyAccountOrder"
                  elem="AdditionalInfo"
                >
                    <div>
                        <MyAccountOrderStatus status={ status } />
                        <span>
                            { this.renderReorderButton() }
                        </span>
                    </div>
                    <div>
                        <span>
                            { increment_id }
                        </span>
                        <span>
                            { order_date ? formatDateWithSlashes(order_date) : '' }
                        </span>
                    </div>
                    <div>
                        <div block="ShippingNumber">
                            { this.renderTrackingNumbers(tracking) }
                        </div>
                        <span>
                            { orderTotal }
                        </span>

                    </div>
                </div>
            );
        }

        return (
            <table
              block="MyAccountOrder"
              elem="AdditionalInfo"
            >
                <thead>
                    <tr>
                        <th>{ __('Order') }</th>
                        <th>{ __('Date') }</th>
                        <th>{ __('Status') }</th>
                        <th>{ __('Shipping nr') }</th>
                        <th>{ __('Sum') }</th>
                        <th>{ __('Actions') }</th>
                    </tr>
                </thead>
                <tbody>
                    <td>{ increment_id }</td>
                    <td>{ order_date ? formatDateWithSlashes(order_date) : '' }</td>
                    <td><MyAccountOrderStatus status={ status } /></td>
                    <td block="ShippingNumber">
                        { this.renderTrackingNumbers(tracking) }
                    </td>
                    <td>
                        { orderTotal }
                    </td>
                    <td>
                        { this.renderReorderButton() }
                    </td>
                </tbody>
            </table>
        );
    }
}

export default MyAccountOrder;
