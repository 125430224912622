/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.config';

export const INPOST = 'inpost';
export const FLAT_RATE = 'flatrate';
export const PICKUP = 'pickup';
export const DPD = 'Dpdshipping';
export const DPDCOD = 'Dpdshippingcod';
