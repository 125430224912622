/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    RadioButton as SourceRadioButton
} from 'SourceComponent/RadioButtonIcon/RadioButtonIcon.component';

/** @namespace Satisfly/Component/RadioButtonIcon/Component */
export class RadioButton extends SourceRadioButton {
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="RadioButtonIcon"
              mods={ { isActive } }
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <g id="Radiobuttons=check">
                    <circle id="Ellipse 2" cx="10" cy="10" r="9" stroke="#AC1C42" strokeWidth="2" />
                    <circle id="Ellipse 3" cx="10" cy="10" r="4.5" fill="#AC1C42" stroke="#AC1C42" />
                </g>
            </svg>
        );
    }
}

export default RadioButton;
