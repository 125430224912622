/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountTabListContainer as SourceMyAccountTabListContainer
} from 'SourceComponent/MyAccountTabList/MyAccountTabList.container';

export { mapDispatchToProps };

/** @namespace Satisfly/Component/MyAccountTabList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device
});

/** @namespace Satisfly/Component/MyAccountTabList/Container */
export class MyAccountTabListContainer extends SourceMyAccountTabListContainer {
    containerProps() {
        const { device } = this.props;

        return {
            ...super.containerProps(),
            device
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountTabListContainer);
