/* eslint-disable spaced-comment */

import getStore from 'Util/Store';
import { STORE_DE_CODE, STORE_EN_CODE } from 'Util/Store/Config';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { VALIDATION_INPUT_TYPE_INPUT } from './Config';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
export * from 'SourceUtil/Validator/Validator';

/** @namespace Satisfly/Util/Validator/getRuleByStore */
export const getRuleByStore = (inputType) => {
    const storeCode = getStore().getState().ConfigReducer.code;

    switch (storeCode) {
    case STORE_EN_CODE:
        return VALIDATION_INPUT_TYPE[`${inputType}English`];
    case STORE_DE_CODE:
        return VALIDATION_INPUT_TYPE[`${inputType}German`];
    default:
        return VALIDATION_INPUT_TYPE[inputType];
    }
};

/** @namespace Satisfly/Util/Validator/getPostCodeRule */
export const getPostCodeRule = () => getRuleByStore(VALIDATION_INPUT_TYPE_INPUT.postcode);

/** @namespace Satisfly/Util/Validator/getVatRule */
export const getVatRule = () => getRuleByStore(VALIDATION_INPUT_TYPE_INPUT.vat);

/** @namespace Satisfly/Util/Validator/getPhoneRule */
export const getPhoneRule = () => getRuleByStore(VALIDATION_INPUT_TYPE_INPUT.phone);
