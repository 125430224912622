/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    EDIT_ADDRESS
} from 'Component/MyAccountAddressPopup/MyAccountAddressPopup.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountAddressTableContainer as SourceMyAccountAddressTableContainer
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Satisfly/Component/MyAccountAddressTable/Container */
export class MyAccountAddressTableContainer extends SourceMyAccountAddressTableContainer {
    static propTypes = {
        ...super.propTypes,
        isOrder: PropTypes.bool,
        isBilling: PropTypes.bool,
        isShipping: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        isOrder: false,
        isBilling: false,
        isShipping: false
    };

    containerProps() {
        const {
            isMobile,
            isOrder,
            isBilling,
            isShipping
        } = this.props;

        return {
            ...super.containerProps(),
            isMobile,
            isOrder,
            isBilling,
            isShipping
        };
    }

    onEditClick() {
        const { showEditPopup, address, addressLabel } = this.props;

        showEditPopup({
            action: EDIT_ADDRESS,
            title: __('Edit %s', addressLabel),
            address
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressTableContainer);
