/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';

import './ShowMore.style';

/** @namespace Satisfly/Component/ShowMore/Component */
export class ShowMore extends PureComponent {
    static propTypes = {
        showMoreItems: PropTypes.node.isRequired,
        getShowMoreItemHeading: PropTypes.func.isRequired,
        getShowMoreItemExpandedHeading: PropTypes.func.isRequired,
        getShowMoreItemContent: PropTypes.func.isRequired,
        additionalClassName: PropTypes.string

    };

    static defaultProps = {
        additionalClassName: ''
    };

    render() {
        const {
            showMoreItems, additionalClassName, getShowMoreItemHeading, getShowMoreItemExpandedHeading, getShowMoreItemContent
        } = this.props;

        if (!showMoreItems || !showMoreItems?.length) {
            return null;
        }

        return (
            <div block="ShowMore" mix={ { block: additionalClassName } }>
                { showMoreItems.map((showMoreItem) => (
                    <ExpandableContent
                      heading={ getShowMoreItemHeading(showMoreItem) }
                      expandedHeading={ getShowMoreItemExpandedHeading(showMoreItem) }
                      mix={ { block: 'ShowMore', elem: 'Box' } }
                      alwaysVisible
                      isArrow
                    >
                        { getShowMoreItemContent(showMoreItem) }
                    </ExpandableContent>
                )) }
            </div>
        );
    }
}

export default ShowMore;
