/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceComponent/Header/Header.config';

export const MY_ACCOUNT_MENU_PAGE = 'MY_ACCOUNT_MENU_PAGE';
export const TOP_INFO_BAR = 'top-info-bar';
export const TOP_PROMO_BLOCK = 'top-promo-block';
export const ABOUT_US_PAGE_LINK = '/about-us';
export const TOP_PROMO_BLOCK_HEIGHT = 'top-promo-block-height';
export const BLOG = 'blog';
