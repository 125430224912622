/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import { FieldFileContainer as SourceFieldFileContainer } from 'SourceComponent/FieldFile/FieldFile.container';
import { showNotification } from 'Store/Notification/Notification.action';

/** @namespace Satisfly/Component/FieldFile/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, title, error) => dispatch(showNotification(type, title, error))
});
/** @namespace Satisfly/Component/FieldFile/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});
/* eslint-disable */

/** @namespace Satisfly/Component/FieldFile/Container */
export class FieldFileContainer extends SourceFieldFileContainer {
    containerProps() {
        const { device } = this.props;
        return {
            ...super.containerProps(),
            device,
        };
    }
    readAsDataURL(file) {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = function () {
                return resolve({
                    data: fileReader.result,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                });
            };
            fileReader.readAsDataURL(file);
        });
    }
    getMultipleName(files) {
        let newName = "";
        files.forEach((file) => {
            newName = `${newName}, ${file.name}`;
        });

        return newName;
    }

    async onChange(value) {
        const {
            events: { onChange } = {},
            validate,
            showNotification,
        } = this.props;

        if (this.fieldRef) {
            const { files } = this.fieldRef;
            const isMultipleFiles = files?.length > 1;

            this.setState({ isLoading: true });
            let { name } = files[0] || {};

            if (isMultipleFiles) {
                name = this.getMultipleName(Array.from(files));
            }

            validate();

            if (!name) {
                this.setState({
                    fileName: "",
                    isLoading: false,
                });

                return;
            }

            let images = await Promise.all(
                Array.from(files).map((f) => {
                    return this.readAsDataURL(f);
                })
            );

            const reader = new FileReader();

            reader.onload = () => {
                this.setState({
                    fileName: isMultipleFiles
                        ? this.getMultipleName(images)
                        : name,
                    isLoading: false,
                    value: reader.result,
                });
                this.fieldRef.fileData = isMultipleFiles
                    ? images
                    : JSON.stringify({
                          file_data: reader.result,
                          file_name: name,
                      });

                if (typeof onChange === "function") {
                    onChange(value);
                }
            };
            reader.onerror = () => {
                showNotification("error", __("Failed to upload file"));
                this.setState({ fileName: "", isLoading: false });

                if (typeof onChange === "function") {
                    onChange(value);
                }
            };
            reader.readAsDataURL(files[0]);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldFileContainer);
