/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { MyAccountCustomerForm as SourceMyAccountCustomerForm } from 'SourceComponent/MyAccountCustomerForm/MyAccountCustomerForm.component';

import { customerEmailAndPasswordFields, customerInformationFields } from './MyAccountCustomerForm.form';

/** @namespace Satisfly/Component/MyAccountCustomerForm/Component */
export class MyAccountCustomerForm extends SourceMyAccountCustomerForm {
    get customerInformationFieldMap() {
        const {
            showTaxVatNumber,
            handleChangePasswordCheckbox,
            showPasswordChangeField,
            vatNumberRequired,
            customer: {
                firstname = '',
                lastname = '',
                taxvat = ''
            }
        } = this.props;

        return customerInformationFields({
            showTaxVatNumber,
            firstname,
            lastname,
            taxvat,
            handleChangePasswordCheckbox,
            showPasswordChangeField,
            vatNumberRequired
        });
    }

    get emailAndPasswordFieldMap() {
        const {
            minimunPasswordCharacter,
            showEmailChangeField,
            showPasswordChangeField,
            handlePasswordInput,
            handleEmailInput,
            currentPassword,
            email,
            range
        } = this.props;

        return customerEmailAndPasswordFields({
            minimunPasswordCharacter,
            showEmailChangeField,
            showPasswordChangeField,
            handlePasswordInput,
            handleEmailInput,
            currentPassword,
            email,
            range
        });
    }

    renderEmailAndPasswordFields() {
        const { showEmailChangeField, showPasswordChangeField } = this.props;

        if (!showEmailChangeField && !showPasswordChangeField) {
            return null;
        }

        return (
            <>
                <legend
                  block="FieldForm"
                  elem="Legend"
                >
                    { this.getLegendString() }
                </legend>
                { this.emailAndPasswordFieldMap.map(this.renderSection) }
            </>
        );
    }

    getLegendString() {
        const { showEmailChangeField, showPasswordChangeField } = this.props;

        if (showEmailChangeField && !showPasswordChangeField) {
            return __('Change Email');
        }

        if (!showEmailChangeField && showPasswordChangeField) {
            return __('Change Password');
        }

        return __('Change Email and Password');
    }

    renderFormBody() {
        return (
            <div block="FieldForm" elem="Body">
                <div block="FieldForm" elem="Fields">
                    <div block="FieldForm" elem="Section">
                    <legend
                      block="FieldForm"
                      elem="Legend"
                    >
                        { __('Account') }
                    </legend>
                        { this.customerInformationFieldMap.map(this.renderSection) }
                    </div>
                    <div
                      block="FieldForm"
                      elem="Section"
                      mix={ { block: 'FieldForm', elem: 'SectionWithSpace' } }
                    >
                        { this.renderEmailAndPasswordFields() }
                    </div>
                </div>
                { this.renderActions() }
            </div>
        );
    }
}

export default MyAccountCustomerForm;
