/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import Link from 'Component/Link';
import { LOGIN_PRIVATE_REDIRECT_URL, LOGIN_PROFESSIONAL_REDIRECT_URL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';

/** @namespace Satisfly/Component/MyAccountModalAdditional/Component */
export class MyAccountModalAdditional extends PureComponent {
    render() {
        return (
            <>
                <section>
                    <p>{ __("Don't have an account?") }</p>
                    <Link
                      to={ LOGIN_PROFESSIONAL_REDIRECT_URL }
                      aria-label={ __('Create a professional account') }
                    >
                        { __('Create a professional account') }
                    </Link>
                </section>
                <section>
                    <p>{ __('Are you a private person?') }</p>
                    <Link
                      to={ LOGIN_PRIVATE_REDIRECT_URL }
                      aria-label={ __('Create a personal account') }
                    >
                        { __('Create a personal account') }
                    </Link>
                </section>
            </>
        );
    }
}

export default MyAccountModalAdditional;
