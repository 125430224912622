/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const GET_DOCUMENTS_LIST = 'GET_DOCUMENTS_LIST';
export const GET_DOCUMENTS_LIST_PAGE_DATA = 'GET_DOCUMENTS_LIST_PAGE_DATA';
export const SET_DOCUMENTS_LOADING_STATUS = 'SET_DOCUMENTS_LOADING_STATUS';

/** @namespace Satisfly/Store/MyDocuments/Action/getDocumentList */
export const getDocumentList = (documentList, status) => ({
    type: GET_DOCUMENTS_LIST,
    documentList,
    status
});

/** @namespace Satisfly/Store/MyDocuments/Action/getDocumentListPageData */
export const getDocumentListPageData = (pageData) => ({
    type: GET_DOCUMENTS_LIST_PAGE_DATA,
    pageData
});

/** @namespace Satisfly/Store/MyDocuments/Action/setLoadingStatus */
export const setLoadingStatus = (status) => ({
    type: SET_DOCUMENTS_LOADING_STATUS,
    status
});
