/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy, Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import Loader from 'Component/Loader/Loader.component';
import MyAccountTabList from 'Component/MyAccountTabList';
import { ACCOUNT_ORDER_HISTORY, ACCOUNT_ORDER_URL } from 'Route/MyAccount/MyAccount.config';
import NoMatch from 'Route/NoMatch';
import {
    MyAccount as SourceMyAccount,
    MyAccountAddressBook,
    MyAccountDashboard,
    MyAccountDownloadable,
    MyAccountMyOrders,
    MyAccountMyWishlist,
    MyAccountNewsletterSubscription
} from 'SourceRoute/MyAccount/MyAccount.component';
import {
    MY_DOCUMENTS,
    MY_ORDERS
} from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';

import './MyAccount.override.style';

export {
    MyAccountAddressBook,
    MyAccountDashboard,
    MyAccountDownloadable,
    MyAccountMyOrders,
    MyAccountMyWishlist,
    MyAccountNewsletterSubscription
};

export const MyDocuments = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-orders" */
    'Component/MyDocuments'
));

/** @namespace Satisfly/Route/MyAccount/Component */
export class MyAccount extends SourceMyAccount {
    renderMap = {
        ...this.renderMap,
        [MY_DOCUMENTS]: MyDocuments
    };

    renderBackLink() {
        const {
            activeTab,
            location: { pathname }

        } = this.props;

        const backLinkTabs = [MY_ORDERS];

        if (backLinkTabs.includes(activeTab) && pathname.includes(ACCOUNT_ORDER_URL)) {
            return (
                <Link
                  to={ ACCOUNT_ORDER_HISTORY }
                  block="MyAccount"
                  elem="BackBtn"
                  mix={ { block: 'Button', mods: { underlinedLeftArrowLink: true } } }
                  aria-label={ __('Back to orders') }
                >
                      { __('Back to orders') }
                </Link>
            );
        }

        return null;
    }

    renderContent() {
        const {
            activeTab,
            tabMap,
            changeActiveTab,
            onSignOut,
            isEditingActive,
            match,
            changeTabName,
            tabName,
            setTabSubheading,
            isTabEnabled
        } = this.props;

        if (!isSignedIn()) {
            return this.renderLoginOverlay();
        }

        if (!isTabEnabled(activeTab)) {
            return <NoMatch />;
        }

        const TabContent = this.getTabContent();
        const { title } = tabMap[activeTab];

        return (
            <ContentWrapper
              label={ __('My Account page') }
              wrapperMix={ { block: 'MyAccount', elem: 'Wrapper' } }
            >
                <MyAccountTabList
                  tabMap={ tabMap }
                  activeTab={ activeTab }
                  changeActiveTab={ changeActiveTab }
                  onSignOut={ onSignOut }
                />
                <div
                  block="MyAccount"
                  elem="TabContent"
                  mods={ { activeTab } }
                >
                    { this.renderBackLink() }

                    <h2 block="MyAccount" elem="Heading">
                        { title || tabName }
                        { this.renderSubHeading() }
                    </h2>
                    <Suspense fallback={ <Loader /> }>
                        <TabContent
                          isEditingActive={ isEditingActive }
                          match={ match }
                          changeTabName={ changeTabName }
                          tabMap={ tabMap }
                          setTabSubheading={ setTabSubheading }
                        />
                    </Suspense>
                </div>
            </ContentWrapper>
        );
    }
}

export default MyAccount;
