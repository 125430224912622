/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import Loader from 'Component/Loader';
import {
    MyAccountAddressTable as SourceMyAccountAddressTable
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

/** @namespace Satisfly/Component/MyAccountAddressTable/Component */
export class MyAccountAddressTable extends SourceMyAccountAddressTable {
    static propTypes = {
        ...super.propTypes,
        isOrder: PropTypes.bool.isRequired,
        isBilling: PropTypes.bool.isRequired,
        isShipping: PropTypes.bool.isRequired
    };

    renderEditBtn() {
        const {
            onEditClick,
            showActions,
            address: { default_billing }
        } = this.props;

        if (!showActions || default_billing) {
            return null;
        }

        return (
            <button
              block="Button"
              onClick={ onEditClick }
              mods={ { underlinedLink: true } }
            >
                { __('Edit') }
            </button>
        );
    }

    renderActions() {
        const {
            onEditClick,
            onDeleteClick,
            showActions,
            isMobile,
            address: { default_billing, default_shipping }
        } = this.props;

        const isDeleteAllowed = default_shipping || default_billing;

        if (!showActions || default_billing) {
            return null;
        }

        return (
            <>
                { isMobile && (
                    <button
                      block="Button"
                      onClick={ onEditClick }
                      mods={ { isHollow: true, medium: true } }
                    >
                        { __('Edit') }
                    </button>
                ) }
                <button
                  block="Button"
                  mods={ { isHollow: true, medium: true } }
                  onClick={ onDeleteClick }
                  disabled={ isDeleteAllowed }
                  title={ isDeleteAllowed ? __('Can not delete - address is set as default.') : 'Delete this address' }
                >
                    { __('Delete') }
                </button>
            </>
        );
    }

    render() {
        const { countries, mix, isMobile } = this.props;

        return (
            <div block="MyAccountAddressTable" mix={ mix }>
                <Loader isLoading={ !countries.length } />
                { !isMobile && this.renderEditBtn() }
                { this.renderTable() }
                { this.renderActions() }
            </div>
        );
    }
}

export default MyAccountAddressTable;
