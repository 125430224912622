/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ExpandableContent from 'Component/ExpandableContent';
import Link from 'Component/Link';
import {
    ORDER_ACTION_LABELS
} from 'Component/MyAccountOrder/MyAccountOrder.config';
import MyAccountOrderItemsTableRow from 'Component/MyAccountOrderItemsTableRow';
import {
    MyAccountOrderItemsTable as SourceMyAccountOrderItemsTable
} from 'SourceComponent/MyAccountOrderItemsTable/MyAccountOrderItemsTable.component';
import { getProductFromOrder } from 'Util/Orders';
import { appendWithStoreCode } from 'Util/Url';

import './MyAccountOrderItemsTable.override.style';

/** @namespace Satisfly/Component/MyAccountOrderItemsTable/Component */
export class MyAccountOrderItemsTable extends SourceMyAccountOrderItemsTable {
    renderPrintAction() {
        const { activeTab, id, isPrintPage } = this.props;

        const { print: printLabel, printUrl } = ORDER_ACTION_LABELS[activeTab] || {};

        if (!printLabel || isPrintPage) {
            return null;
        }

        return (
            <Link
              block="MyAccountOrderItemsTable"
              elem="PrintOrder"
              to={ appendWithStoreCode(`${printUrl}/${id}`) }
              isOpenInNewTab
              aria-label={ printLabel }
            >
                { printLabel }
            </Link>
        );
    }

    renderItemRow(product) {
        const { activeTab, allOrderItems, items: { comments = [] } } = this.props;
        const { product_sku, product_url_key, product_name } = product;
        const {
            entered_options = [],
            selected_options = []
        } = getProductFromOrder(allOrderItems, product_sku) || {};
        const { isMobile } = this.props;

        if (isMobile) {
            return (
                <ExpandableContent heading={ product_name } isArrow>
                    <MyAccountOrderItemsTableRow
                      product={ product }
                      selectedOptions={ selected_options }
                      enteredOptions={ entered_options }
                      key={ product_url_key }
                      activeTab={ activeTab }
                      comments={ comments }
                    />
                </ExpandableContent>
            );
        }

        return (
            <MyAccountOrderItemsTableRow
              product={ product }
              selectedOptions={ selected_options }
              enteredOptions={ entered_options }
              key={ product_url_key }
              activeTab={ activeTab }
              comments={ comments }
            />
        );
    }

    renderItemsHeading() {
        return (
            <tr
              block="MyAccountOrderItemsTable"
              elem="Headings"
            >
                <th
                  block="MyAccountOrderItemsTable"
                  elem="Name"
                >
                    { __('Product') }
                </th>
                <th
                  block="MyAccountOrderItemsTable"
                  elem="ProductSku"
                >
                    { __('SKU') }
                </th>
                { this.renderPriceHeading() }
                <th
                  block="MyAccountOrderItemsTable"
                  elem="Quantity"
                >
                    { __('Qty') }
                </th>
                { this.renderSubtotalHeading() }
                { this.renderRefundsTableHeading() }
            </tr>
        );
    }

    render() {
        return (
            <div block="MyAccountOrderItemsTable" elem="ProductsWrapper">
                <div block="MyAccountOrderItemsTable" elem="ProductsTableWrapper">
                    <table
                      block="MyAccountOrderItemsTable"
                      elem="Products"
                    >
                        <thead>
                            { this.renderItemsHeading() }
                        </thead>
                        { this.renderItems() }
                    </table>
                </div>
                { this.renderTotals() }
            </div>
        );
    }
}

export default MyAccountOrderItemsTable;
