/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
export * from 'SourceComponent/NavigationAbstract/NavigationAbstract.config';

export const PAGE_VISIT_DEBOUNCE_DELAY = 200;
export const PREVIOUS_ROUTE = 'PREVIOUS_ROUTE';
