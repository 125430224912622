/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ChevronIcon from 'Component/ChevronIcon';
import Link from 'Component/Link';
import {
    MyAccountTabListItem as SourceMyAccountTabListItem
} from 'SourceComponent/MyAccountTabListItem/MyAccountTabListItem.component';

import './MyAccountTabListItem.override.style';

/** @namespace Satisfly/Component/MyAccountTabListItem/Component */
export class MyAccountTabListItem extends SourceMyAccountTabListItem {
    render() {
        const {
            children, tabEntry: [, { tabName, url, menuUrl }], isActive, isMyAccountMenuPage
        } = this.props;

        if (isMyAccountMenuPage) {
            return (
                <li block="MyAccountTabListItem" mods={ { isActive, isMyAccountMenuPage: true } }>
                    <Link
                      to={ menuUrl || url }
                      aria-label={ __('Link to %s', tabName) }
                    >
                        <button block="MyAccountTabListItem" elem="Button">
                            { tabName }
                            <ChevronIcon />
                        </button>
                        { children }
                    </Link>
                </li>
            );
        }

        return (
            <li
              block="MyAccountTabListItem"
              mods={ { isActive } }
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  onClick={ this.changeActiveTab }
                  role="link"
                >
                    { tabName }
                    <ChevronIcon />
                </button>
                { children }
            </li>
        );
    }
}

export default MyAccountTabListItem;
