/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import getStore from 'Util/Store';

export * from 'SourceUtil/Address';

export const defaultCountry = getStore().getState()?.ConfigReducer?.default_country || 'PL';

/** @namespace Satisfly/Util/Address/Index/trimCustomerAddress */
export const trimCustomerAddress = (customerAddress) => {
    const {
        default_shipping = false,
        default_billing = false,
        company = null,
        city = '',
        country_id = defaultCountry,
        firstname = '',
        lastname = '',
        middlename = '',
        postcode = '',
        street = [''],
        telephone = '',
        region: {
            region_code = null,
            region = null,
            region_id = 1
        } = {},
        prefix = '',
        suffix = '',
        vat_id = null
    } = customerAddress;

    return {
        company,
        default_shipping,
        default_billing,
        city,
        country_id,
        firstname,
        lastname,
        middlename,
        postcode,
        street,
        telephone,
        region: {
            region_code,
            region,
            region_id
        },
        prefix,
        suffix,
        vat_id
    };
};

/** @namespace Satisfly/Util/Address/Index/trimCheckoutCustomerAddress */
export const trimCheckoutCustomerAddress = (customerAddress) => {
    const {
        company = null,
        city = '',
        country_id = defaultCountry,
        firstname = '',
        lastname = '',
        postcode = '',
        street = [''],
        telephone = '',
        region: {
            region_code = null,
            region = null,
            region_id = 1
        } = {},
        vat_id = null
    } = customerAddress;

    return {
        company,
        city,
        country_id,
        firstname,
        lastname,
        postcode,
        street,
        telephone,
        region,
        region_id,
        region_code,
        vat_id
    };
};

/** @namespace Satisfly/Util/Address/Index/trimCheckoutAddress */
export const trimCheckoutAddress = (customerAddress) => {
    const {
        company = null,
        city = '',
        country_id = defaultCountry,
        firstname = '',
        lastname = '',
        postcode = '',
        street = [''],
        telephone = '',
        region_string = '',
        region_id = 0,
        region_code = null,
        vat_id = null
    } = customerAddress;

    return {
        company,
        city,
        country_id,
        firstname,
        lastname,
        postcode,
        street,
        telephone,
        region: region_string,
        region_id: region_id === '' ? 0 : region_id,
        region_code,
        vat_id
    };
};

/** @namespace Satisfly/Util/Address/Index/getFormattedRegion */
export const getFormattedRegion = (address, countries) => {
    const {
        country_id = defaultCountry,
        region: regionData
    } = address;

    const country = countries.find(({ id }) => id === country_id);

    if (!country) {
        return {};
    }

    const { label = null, available_regions } = country;

    if (!regionData) {
        return (label && { country: label }) || {};
    }

    if (typeof regionData === 'string') {
        return {
            country: label,
            region: regionData
        };
    }

    const regions = available_regions || [];
    const { region_id, region } = regionData;
    const { name } = regions.find(({ id }) => id === region_id) || { name: region };

    return {
        country: label,
        region: name
    };
};
