/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ExpandableContent from 'Component/ExpandableContent';
import {
    MyAccountTabList as SourceMyAccountTabList
} from 'SourceComponent/MyAccountTabList/MyAccountTabList.component';

import './MyAccountTabList.override.style';

/** @namespace Satisfly/Component/MyAccountTabList/Component */
export class MyAccountTabList extends SourceMyAccountTabList {
    renderLogoutTab() {
        const { handleLogout } = this.props;

        return (
            <li
              key="logout"
              block="MyAccountTabListItem"
            >
                <button
                  mix={ { block: 'Button', mods: { isHollow: true, medium: true } } }
                  onClick={ handleLogout }
                  role="link"
                >
                    { __('Logout') }
                </button>
            </li>
        );
    }

    render() {
        const {
            activeTab,
            isContentExpanded,
            tabMap,
            device: { isMobile } = {},
            toggleExpandableContent
        } = this.props;
        const { tabName } = tabMap[activeTab];

        if (isMobile) {
            // tablist is handled by separate route MyAccountMenuPage on mobile
            return null;
        }

        const tabs = [
            ...Object.entries(tabMap).map(this.renderTabListItem.bind(this)),
            this.renderLogoutTab()
        ];

        return (
            <ExpandableContent
              heading={ tabName }
              isContentExpanded={ isContentExpanded }
              mix={ { block: 'MyAccountTabList' } }
              onClick={ toggleExpandableContent }
              mods={ { isWithoutBorder: true } }
            >
                <ul>
                    { tabs }
                </ul>
            </ExpandableContent>
        );
    }
}

export default MyAccountTabList;
