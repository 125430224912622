/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace Satisfly/Component/ExpandableContent/Component */
export class ExpandableContent extends SourceExpandableContent {
    static propTypes = {
        ...super.propTypes,
        expandedHeading: PropTypes.string
    };

    static defaultProps = {
        ...super.defaultProps,
        expandedHeading: ''
    };

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow, device: { isMobile }, alwaysVisible = false } = this.props;

        if (!isMobile && !alwaysVisible) {
            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    renderHeading() {
        const { isContentExpanded } = this.state;
        const { heading = '', expandedHeading = '' } = this.props;

        const headingWhenExpanded = expandedHeading || heading;

        if (isContentExpanded) {
            return typeof headingWhenExpanded === 'string' ? <TextPlaceholder content={ headingWhenExpanded } length="medium" /> : headingWhenExpanded;
        }

        return typeof heading === 'string' ? <TextPlaceholder content={ heading } length="medium" /> : heading;
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const {
            mix,
            alwaysVisible = false,
            heading = ''
        } = this.props;

        return (
             <div
               role="button"
               tabIndex={ 0 }
               block="ExpandableContent"
               elem="Button"
               aria-label={ __('Expand %s', heading) }
               mods={ { isContentExpanded, alwaysVisible } }
               mix={ { ...mix, elem: 'ExpandableContentButton' } }
               onClick={ this.toggleExpand }
               onKeyDown={ this.toggleExpand }
             >
                 <div
                   block="ExpandableContent"
                   elem="Heading"
                   mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                 >
                    { this.renderHeading() }
                 </div>
                 { this.renderButtonIcon() }
             </div>
        );
    }

    renderContent() {
        const { children, mix, allowDesktopExpandable = false } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded, allowDesktopExpandable };

        return (
            <div
              block="ExpandableContent"
              elem="Content"
              mods={ mods }
              mix={ { ...mix, elem: 'ExpandableContentContent', mods } }
            >
                { children }
            </div>
        );
    }

    render() {
        const { mix, mods } = this.props;
        const { isContentExpanded } = this.state;

        return (
            <article
              block="ExpandableContent"
              mix={ mix }
              mods={ { isContentExpanded, ...mods } }
              ref={ this.expandableContentRef }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
}

export default ExpandableContent;
