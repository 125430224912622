/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    GET_DOCUMENTS_LIST,
    GET_DOCUMENTS_LIST_PAGE_DATA,
    SET_DOCUMENTS_LOADING_STATUS
} from './MyDocuments.action';

/** @namespace Satisfly/Store/MyDocuments/Reducer/getInitialState */
export const getInitialState = () => ({
    documentList: [],
    pageData: {},
    isLoading: true
});

/** @namespace Satisfly/Store/MyDocuments/Reducer/MyDocumentsReducer */
export const MyDocumentsReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        documentList,
        status,
        pageData
    } = action;

    switch (type) {
    case GET_DOCUMENTS_LIST:
        return {
            ...state,
            isLoading: status,
            documentList
        };
    case GET_DOCUMENTS_LIST_PAGE_DATA:
        return {
            ...state,
            pageData
        };
    case SET_DOCUMENTS_LOADING_STATUS:
        return {
            ...state,
            isLoading: status
        };
    default:
        return state;
    }
};

export default MyDocumentsReducer;
