/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ArrowIcon from 'Component/ArrowIcon';
import { BOTTOM, TOP } from 'Component/ArrowIcon/ArrowIcon.config';

import './ShowMoreLessContent.style.scss';

/** @namespace Satisfly/Component/ShowMoreLessContent/Component */
export class ShowMoreLessContent extends PureComponent {
    static propTypes = {
        handleClick: PropTypes.func.isRequired,
        contentMaxLength: PropTypes.number.isRequired,
        isExpanded: PropTypes.bool.isRequired,
        content: PropTypes.string.isRequired,
        withDots: PropTypes.bool.isRequired,
        isPB: PropTypes.bool.isRequired
    };

    renderButton() {
        const { isExpanded, handleClick } = this.props;

        const buttonTitle = isExpanded ? __('Show less') : __('Show more');

        return (
            <button block="ShowMoreLessContent" elem="Button" onClick={ handleClick }>
                { buttonTitle }
                { isExpanded ? <ArrowIcon direction={ TOP } /> : <ArrowIcon direction={ BOTTOM } /> }
            </button>
        );
    }

    render() {
        const {
            content, isExpanded, contentMaxLength, withDots,
            isPB
        } = this.props;

        if (!content?.length) {
            return null;
        }

        const matchPhrases = ['decoded="true">', 'data-element="main">'];
        const indexList = matchPhrases.map((phrase) => content.lastIndexOf(phrase));

        const isExtendable = content.length > contentMaxLength;
        const startingIndex = isPB ? (indexList.find((el) => el > -1) || 0) : 0;
        const currentContent = isExtendable && !isExpanded
            ? `${content.slice(content, startingIndex + contentMaxLength).trim()}${withDots ? '...' : ''}`
            : content;

        return (
            <div block="ShowMoreLessContent" elem="Wrapper">
                <span block="ShowMoreLessContent" elem="Content" mods={ { isExpanded } }>
                    { parser(currentContent?.toString()) }
                    { isExtendable && this.renderButton() }
                </span>
            </div>
        );
    }
}

export default ShowMoreLessContent;
