/* eslint-disable spaced-comment,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { Field as SourceField } from 'SourceComponent/Field/Field.component';

import { FIELD_TYPE } from './Field.config';

import './Field.style';

/**
 * Field
 * @class Field
 * @namespace Satisfly/Component/Field/Component */
export class Field extends SourceField {
    static propTypes = {
        ...SourceField.propTypes,
        mask: PropTypes.string,
        formatChars: PropTypes.object
    };

    static defaultProps = {
        ...SourceField.defaultProps,
        formatChars: {
            9: '[0-9]',
            a: '[A-Za-z]',
            '*': '[A-Za-z0-9]'
        }
    };

    renderMap = {
        // Checkboxes & Radio
        [FIELD_TYPE.radio]: this.renderCheckboxOrRadio.bind(this),
        [FIELD_TYPE.checkbox]: this.renderCheckboxOrRadio.bind(this),
        [FIELD_TYPE.multi]: this.renderCheckboxOrRadio.bind(this),

        // Default input
        [FIELD_TYPE.email]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.text]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.time]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.dateTime]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.date]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.password]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.submit]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.tel]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.number]: this.renderDefaultInput.bind(this),

        // Custom fields
        [FIELD_TYPE.file]: this.renderFile.bind(this),
        [FIELD_TYPE.select]: this.renderSelect.bind(this),
        [FIELD_TYPE.textarea]: this.renderTextArea.bind(this),
        [FIELD_TYPE.button]: this.renderButton.bind(this),
        [FIELD_TYPE.numberWithControls]: this.renderNumberWithControls.bind(this),
        [FIELD_TYPE.mask]: this.renderMaskInput.bind(this)

    };

    renderMaskInput() {
        const {
            setRef, attr, events, mask, formatChars
        } = this.props;

        return (
            <InputMask
              mask={ mask }
              maskPlaceholder="-"
              type="text"
              formatChars={ formatChars }
              { ...attr }
              { ...events }
            >
                { (inputProps) => (
                    <input type="text" ref={ (elem) => setRef(elem) } { ...inputProps } />
                ) }
            </InputMask>
        );
    }
}

export default Field;
