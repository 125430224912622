/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import { BILLING_URL, SHIPPING_URL } from 'Route/Checkout/Checkout.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Satisfly/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    async onSignInSuccess(form, fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState,
            totals: { is_virtual },
            isCheckout
        } = this.props;

        const {
            isSignIn
        } = this.state;

        setLoadingState(true);
        const fieldPairs = transformToNameValuePair(fields);

        if (!isSignIn) {
            this.setState({ isSignIn: true });

            try {
                const {
                    email
                } = fieldPairs;

                await signIn(fieldPairs);
                onSignIn();

                window.SR.event.sendFormData('login', { email, newsletterAgreement: 'enabled' })
                    .catch(
                    /** @namespace Satisfly/Component/MyAccountSignIn/Container/MyAccountSignInContainer/onSignInSuccess/sendFormData/catch */
                        (err) => {
                            console.error(err);
                        }
                    );
            } catch (error) {
                showNotification('error', getErrorMessage(error));
                this.setState({ isSignIn: false });
            } finally {
                setLoadingState(false);
            }
        }

        setLoadingState(false);

        if (is_virtual && isCheckout) {
            history.push({ pathname: appendWithStoreCode(BILLING_URL) });
        } else if (!is_virtual && isCheckout) {
            history.push({ pathname: appendWithStoreCode(SHIPPING_URL) });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
