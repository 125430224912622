/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const UPDATE_BLOG_LIST = 'UPDATE_BLOG_LIST';
export const UPDATE_BLOG_POST = 'UPDATE_BLOG_POST';

/** @namespace Satisfly/Store/Blog/Action/updateBlogList */
export const updateBlogList = (postList) => ({
    type: UPDATE_BLOG_LIST,
    postList
});

/** @namespace Satisfly/Store/Blog/Action/updateBlogPost */
export const updateBlogPost = (post) => ({
    type: UPDATE_BLOG_POST,
    post
});
