/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ShowMoreLessContent from 'Component/ShowMoreLessContent/ShowMoreLessContent.component';

import { SHOW_MORE_LESS_CONTENT_DEFAULT_LENGTH } from './ShowMoreLessContent.config';

/** @namespace Satisfly/Component/ShowMoreLessContent/Container */
export class ShowMoreLessContentContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        contentMaxLength: PropTypes.number,
        isPB: PropTypes.bool,
        withDots: PropTypes.bool
    };

    state = {
        isExpanded: false
    };

    static defaultProps = {
        contentMaxLength: SHOW_MORE_LESS_CONTENT_DEFAULT_LENGTH,
        isPB: false,
        withDots: false
    };

    containerFunctions = {
        handleClick: this.handleClick.bind(this)
    };

    containerProps() {
        const { isExpanded } = this.state;
        const { contentMaxLength, withDots, isPB } = this.props;

        return {
            content: this.getContent(), isExpanded, contentMaxLength, withDots, isPB
        };
    }

    getContent() {
        const { children, children: { props: { content = '' } = {} } = {}, isPB } = this.props;

        if (isPB) {
            return content;
        }

        return children?.props?.content?.props?.children;
    }

    handleClick(e) {
        e.preventDefault();

        this.setState((prevState) => ({
            isExpanded: !prevState.isExpanded
        }));
    }

    render() {
        return <ShowMoreLessContent { ...this.containerProps() } { ...this.containerFunctions } />;
    }
}

export default ShowMoreLessContentContainer;
