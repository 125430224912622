/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Popup as SourcePopup } from 'SourceComponent/Popup/Popup.component';
import history from 'Util/History';

import './Popup.override.style';

/** @namespace Satisfly/Component/Popup/Component */
export class Popup extends SourcePopup {
    hidePopupAndGoBack() {
        const isVisible = this.getIsVisible();

        if (isVisible) {
            this.hidePopUp();
            history.goBack();
        }
    }
}

export default Popup;
