/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PAGE_VISIT_DEBOUNCE_DELAY, PREVIOUS_ROUTE } from 'Component/NavigationAbstract/NavigationAbstract.config';
import {
    App as SourceApp
} from 'SourceComponent/App/App.component';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import {
    checkForCacheUpdates,
    debounce
} from 'Util/Request';

/** @namespace Satisfly/Component/App/Component */
export class App extends SourceApp {
    state = {
        isSomethingWentWrong: false,
        errorDetails: {},
        actualPathname: ''
    };

    componentDidMount() {
        history.listen(debounce((next) => {
            const {
                actualPathname = ''
            } = this.state;

            if (actualPathname !== next.pathname) {
                BrowserDatabase.setItem(actualPathname, PREVIOUS_ROUTE);
                this.registerPageVisit(next);
            }
        }, PAGE_VISIT_DEBOUNCE_DELAY));

        checkForCacheUpdates();
    }

    componentWillUnmount() {
        BrowserDatabase.deleteItem(PREVIOUS_ROUTE);
    }

    registerPageVisit(next) {
        const { SR } = window;
        SR.event.pageVisit()
            .then(
                /** @namespace Satisfly/Component/App/Component/App/registerPageVisit/pageVisit/then */
                () => {
                    SR.dynamicContent.get();
                    this.setState({ actualPathname: next.pathname });
                }
            );
    }
}

export default App;
