/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

import './Loader.override.style';
/**
 * Loader component
 * Loaders overlay to identify loading
 * @class Loader
 * @namespace Satisfly/Component/Loader/Component */
export class Loader extends SourceLoader {
    static propTypes = {
        ...super.propTypes,
        circle: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        circle: false
    };

    render() {
        const {
            isLoading,
            mix,
            circle
        } = this.props;

        if (!isLoading) {
            return null;
        }

        if (circle) {
            return (
                <div block="LoaderCircle" mix={ mix } />
            );
        }

        return (
            <div block="Loader">
                <div block="Loader" mix={ mix } elem="Scale">
                    { this.renderMain() }
                </div>
            </div>
        );
    }
}

export default Loader;
