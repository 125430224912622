/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    VALIDATION_INPUT_TYPE_INPUT as SOURCE_VALIDATION_INPUT_TYPE_INPUT,
    VALIDATION_INPUT_TYPE_NUMBER,
    VALIDATION_INPUT_TYPE_TEXT,
    VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES
} from 'SourceUtil/Validator/Config';

export * from 'SourceUtil/Validator/Config';

export const VALIDATION_INPUT_TYPE_INPUT = {
    ...SOURCE_VALIDATION_INPUT_TYPE_INPUT,
    postcode: 'postcode',
    postcodeEnglish: 'postcodeEnglish',
    postcodeGerman: 'postcodeGerman',
    vat: 'vat',
    vatEnglish: 'vatEnglish',
    vatGerman: 'vatGerman',
    phoneEnglish: 'phoneEnglish',
    phoneGerman: 'phoneGerman'
};

export const VALIDATION_INPUT_TYPE = {
    ...VALIDATION_INPUT_TYPE_TEXT,
    ...VALIDATION_INPUT_TYPE_NUMBER,
    ...VALIDATION_INPUT_TYPE_INPUT
};

export const VALIDATION_MESSAGES = {
    ...SOURCE_VALIDATION_MESSAGES,
    [VALIDATION_INPUT_TYPE.postcode]: __('Incorrect postcode!'),
    [VALIDATION_INPUT_TYPE.postcodeEnglish]: __('Incorrect postcode!'),
    [VALIDATION_INPUT_TYPE.postcodeGerman]: __('Incorrect postcode!'),
    [VALIDATION_INPUT_TYPE.vat]: __('Incorrect vat number!'),
    [VALIDATION_INPUT_TYPE.vatEnglish]: __('Incorrect vat number!'),
    [VALIDATION_INPUT_TYPE.vatGerman]: __('Incorrect vat number!'),
    [VALIDATION_INPUT_TYPE.phoneEnglish]: __('Incorrect phone input!'),
    [VALIDATION_INPUT_TYPE.phoneGerman]: __('Incorrect phone input!')
};

export const plRegexp = 'a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\\-\\s';

export const VALIDATION_RULES = {
    // Text
    [VALIDATION_INPUT_TYPE.alpha]: new RegExp(`^[${ plRegexp }]+$`, 'i'),
    [VALIDATION_INPUT_TYPE.alphaSpace]: new RegExp(`^[${ plRegexp }]+$`, 'i'),
    [VALIDATION_INPUT_TYPE.alphaNumeric]: /^[a-z0-9]+$/i,
    [VALIDATION_INPUT_TYPE.alphaDash]: /^[a-z0-9_\\-]+$/i,
    [VALIDATION_INPUT_TYPE.url]: /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[\d]+)?(\/|\/([\w#!:.?+=&%@\-\\/]))?$/,

    // Numbers
    [VALIDATION_INPUT_TYPE.numeric]: /^[\d]+$/,
    [VALIDATION_INPUT_TYPE.numericDash]: /^[\d\-\s]+$/,
    [VALIDATION_INPUT_TYPE.integer]: /^\\-?[\d]+$/,
    [VALIDATION_INPUT_TYPE.decimal]: /^\d*\.?\d*$/,
    [VALIDATION_INPUT_TYPE.natural]: /^[\d]+$/i,
    [VALIDATION_INPUT_TYPE.naturalNoZero]: /^[1-9][\d]*$/i,

    // Inputs
    // eslint-disable-next-line max-len
    [VALIDATION_INPUT_TYPE.email]: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/,
    [VALIDATION_INPUT_TYPE.date]: /\d{4}-\d{1,2}-\d{1,2}/,
    [VALIDATION_INPUT_TYPE.postcode]: /^\d{2}-\d{3}$/,
    [VALIDATION_INPUT_TYPE.postcodeEnglish]: /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2})|(\d{4})|(\d{5}\\s\\(\\d{3}\\s\\d{2}\\))|(\d{5})|(\d{4}\\s{0,1}[A-Za-z]{2})|(\d{3}\\s{0,1}\\d{2})|([A-Za-z]\d{2} {0,1}[a-zA-Z0-9]{4}))$/,
    [VALIDATION_INPUT_TYPE.postcodeGerman]: /^((\d{5})|(\d{4})|([A-Za-z]\d{2} {0,1}[a-zA-Z0-9]{4}))$/,
    // eslint-disable-next-line max-len
    [VALIDATION_INPUT_TYPE.phone]: /^(?:(?:(?:(?:\+|00)\d{2})?[ -]?(?:(?:\(0?\d{2}\))|(?:0?\d{2})))?[ -]?(?:\d{3}[- ]?\d{2}[- ]?\d{2}|\d{2}[- ]?\d{2}[- ]?\d{3}|\d{7})|(?:(?:(?:\+|00)\d{2})?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}))$/,
    [VALIDATION_INPUT_TYPE.phoneEnglish]: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
    [VALIDATION_INPUT_TYPE.phoneGerman]: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
    // eslint-disable-next-line max-len
    [VALIDATION_INPUT_TYPE.emailList]: /^(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+([,\s]+(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
    [VALIDATION_INPUT_TYPE.vat]: /^[A-Z\d]{10}$/,
    [VALIDATION_INPUT_TYPE.vatEnglish]: /^[-\w\s]+$/,
    [VALIDATION_INPUT_TYPE.vatGerman]: /^[-\w\s]+$/
};
