/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { STOCK_TYPE } from 'Component/Product/Stock.config';

export * from 'SourceUtil/Product/Extract';

/** @namespace Satisfly/Util/Product/Extract/getAlternativeName */
export const getAlternativeName = (product = {}) => {
    const {
        attributes: {
            alternative_name: {
                attribute_value: alternativeName = null
            } = {}
        } = {}
    } = product;

    return alternativeName;
};

/** @namespace Satisfly/Util/Product/Extract/getProductInStock */
export const getProductInStock = (product, parentProduct = {}) => {
    if (!product) {
        return false;
    }

    const {
        type_id: type,
        variants = [],
        items = [],
        stock_item: {
            in_stock: inStock = true
        } = {},
        is_product_disabled: isProductDisabled
    } = product;

    if (type === PRODUCT_TYPE.bundle) {
        const { items = [] } = product;
        const requiredItems = items.filter(({ required }) => required);
        const requiredItemsInStock = requiredItems.filter(
            ({ options }) => options.some(({ product }) => getProductInStock(product))
        );

        return !isProductDisabled && inStock && requiredItemsInStock.length === requiredItems.length;
    }

    if (type === PRODUCT_TYPE.configurable && parentProduct === product) {
        return !isProductDisabled && inStock && !!variants.some((variant) => getProductInStock(variant, product));
    }

    const { type_id: parentTypeId = false } = parentProduct;

    if (parentTypeId === PRODUCT_TYPE.configurable && parentProduct !== product) {
        const {
            stock_item: {
                in_stock: parentInStock = true
            } = {},
            stock_status: parentStockStatus,
            is_product_disabled: isParentProductDisabled
        } = parentProduct;

        return !isParentProductDisabled && parentInStock && parentStockStatus !== STOCK_TYPE.OUT_OF_STOCK && getProductInStock(product);
    }

    if (type === PRODUCT_TYPE.grouped) {
        return !isProductDisabled && inStock && !!items.some(({ product }) => getProductInStock(product));
    }

    const { stock_status: stockStatus } = product;

    return stockStatus !== STOCK_TYPE.OUT_OF_STOCK && ((!isProductDisabled && inStock) || stockStatus === STOCK_TYPE.IN_STOCK);
};
