/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_WISHLIST
} from 'Component/Header/Header.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountContainer as SourceMyAccountContainer
} from 'SourceRoute/MyAccount/MyAccount.container';
import OrderReducer from 'Store/Order/Order.reducer';
import {
    ACCOUNT_INFORMATION,
    ADDRESS_BOOK,
    FIRST_SECTION,
    MY_ACCOUNT,
    MY_DOCUMENTS,
    MY_ORDERS,
    MY_WISHLIST
} from 'Type/Account.type';
import { withReducers } from 'Util/DynamicReducer';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

SourceMyAccountContainer.tabMap = {
    [MY_ACCOUNT]: {
        url: '',
        tabName: __('My Business Account'),
        menuUrl: 'customer/account',
        section: FIRST_SECTION
    },
    [MY_ORDERS]: {
        url: '/sales/order/history',
        tabName: __('Orders'),
        section: FIRST_SECTION,
        isFullUrl: true
    },
    [MY_DOCUMENTS]: {
        url: '/documents',
        tabName: __('Documents'),
        section: FIRST_SECTION,
        isFullUrl: true
    },
    [ADDRESS_BOOK]: {
        url: '/customer/address',
        tabName: __('Address Book'),
        section: FIRST_SECTION,
        isFullUrl: true
    },
    [ACCOUNT_INFORMATION]: {
        url: '/edit',
        menuUrl: 'customer/account/edit',
        tabName: __('Account Information'),
        title: __('Account Information'),
        section: FIRST_SECTION
    }
};

/** @namespace Satisfly/Route/MyAccount/Container */
export class MyAccountContainer extends SourceMyAccountContainer {
    getHeaderTitle(activeTab) {
        return SourceMyAccountContainer?.tabMap[activeTab]?.tabName || __('My account');
    }

    changeMyAccountHeaderState() {
        const { changeHeaderState } = this.props;
        const { activeTab } = this.state;
        const isActiveTabWishList = activeTab === MY_WISHLIST;

        changeHeaderState({
            title: this.getHeaderTitle(activeTab),
            name: isActiveTabWishList ? CUSTOMER_WISHLIST : CUSTOMER_ACCOUNT_PAGE,
            onBackClick: () => {
                history.push(appendWithStoreCode('/'));
            }
        });
    }
}

export default withRouter(withReducers({
    OrderReducer
})(connect(mapStateToProps, mapDispatchToProps)(MyAccountContainer)));
