/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType } from 'Type/Common.type';

import ShowMore from './ShowMore.component';
import {
    SHOW_MORE_BOX_CLASS, SHOW_MORE_CONTENT_CLASS, SHOW_MORE_HEADER_CLASS, SHOW_MORE_HEADER_EXPANDED_CLASS
} from './ShowMore.config';

/** @namespace Satisfly/Component/ShowMore/Container */
export class ShowMoreContainer extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        className: PropTypes.string
    };

    static defaultProps = {
        className: ''
    };

    containerFunctions = {
        getShowMoreItemHeading: this.getShowMoreItemHeading.bind(this),
        getShowMoreItemContent: this.getShowMoreItemContent.bind(this),
        getShowMoreItemExpandedHeading: this.getShowMoreItemExpandedHeading.bind(this)
    };

    containerProps() {
        const { className } = this.props;

        return {
            additionalClassName: className,
            showMoreItems: this.prepareShowMoreItems()
        };
    }

    getShowMoreItemHeading(item) {
        return item.props.children.filter((child) => child.props.className === SHOW_MORE_HEADER_CLASS)[0];
    }

    getShowMoreItemExpandedHeading(item) {
        return item.props.children.filter((child) => child.props.className === SHOW_MORE_HEADER_EXPANDED_CLASS)[0];
    }

    getShowMoreItemContent(item) {
        return item.props.children.filter((child) => child.props.className === SHOW_MORE_CONTENT_CLASS)[0];
    }

    prepareShowMoreItems() {
        const { children } = this.props;

        return children.filter((child) => child.props.className === SHOW_MORE_BOX_CLASS);
    }

    render() {
        return (
            <ShowMore
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ShowMoreContainer;
